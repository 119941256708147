import { createAction } from '@reduxjs/toolkit';

import { ACTIONS } from './constants';

export const setShowBurgerMenu = createAction<boolean>(
  ACTIONS.SHOW_BURGER_MENU,
);

export const setShowSidebarMenu = createAction<boolean>(
  ACTIONS.SHOW_SIDEBAR_MENU,
);

export const setShowProjectDrawer = createAction<boolean>(
  ACTIONS.SHOW_PROJECT_DRAWER,
);

export const setActiveProject = createAction<string>(ACTIONS.ACTIVE_PROJECT);

export const setShowOrderDrawer = createAction<boolean>(
  ACTIONS.SHOW_ORDER_DRAWER,
);

export const setActiveOrder = createAction<string>(ACTIONS.ACTIVE_ORDER);

export const setShowCustomerDrawer = createAction<boolean>(
  ACTIONS.SHOW_CUSTOMER_DRAWER,
);

export const setActiveCustomer = createAction<string>(ACTIONS.ACTIVE_CUSTOMER);
