import { ButtonVariant } from '@/types';
import styles from './Button.module.scss';
import cn from 'classnames';
import { MouseEvent } from 'react';

type ButtonProps = {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  tabIndex?: number;
  variant?: ButtonVariant;
  icon?: string;
};

export function Button({
  text,
  onClick,
  disabled,
  className,
  type,
  tabIndex,
  variant = 'light',
  icon,
}: ButtonProps) {
  const buttonClassName = cn(
    styles.button,
    {
      [styles.disabled]: disabled,
      [styles.dark]: variant === 'dark',
      [styles.ghost]: variant === 'ghost',
    },
    className,
  );

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    if (disabled) return;
    onClick();
  };

  return (
    <button
      className={buttonClassName}
      onClick={(e) => handleClick(e)}
      type={type}
      tabIndex={tabIndex}
    >
      {text}
      {icon && <img src={icon} alt={text} className={styles.icon} />}
    </button>
  );
}
