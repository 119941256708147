export const APP_REDUCER_NAME = 'appContainer';

export enum ACTIONS {
  SHOW_BURGER_MENU = 'SHOW_BURGER_MENU',
  SHOW_SIDEBAR_MENU = 'SHOW_SIDEBAR_MENU',
  SHOW_PROJECT_DRAWER = 'SHOW_PROJECT_DRAWER',
  ACTIVE_PROJECT = 'ACTIVE_PROJECT',
  SHOW_ORDER_DRAWER = 'SHOW_ORDER_DRAWER',
  ACTIVE_ORDER = 'ACTIVE_ORDER',
  SHOW_CUSTOMER_DRAWER = 'SHOW_CUSTOMER_DRAWER',
  ACTIVE_CUSTOMER = 'ACTIVE_CUSTOMER',
}
