'use client';
import cn from 'classnames';
import styles from './BurgerDrawer.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectShowBurgerMenu, setShowBurgerMenu } from '@/store';
import { Logo } from '@/atoms/Logo';
import { Backdrop } from '@/atoms/Backdrop';
import { useRouter } from 'next/navigation';
import { useAdaptiveLayout } from '@/hooks/useAdaptiveLayout';
import { useNavigationLinks } from '@/hooks/useNavigationLinks';

type BurgerDrawerProps = {
  className?: string;
};

export function BurgerDrawer({ className }: BurgerDrawerProps) {
  const showBurgerMenu = useSelector(selectShowBurgerMenu);
  const dispatch = useDispatch();
  const { push } = useRouter();
  const { isTablet } = useAdaptiveLayout();
  const navigation = useNavigationLinks();

  const wrapperClassName = cn(
    styles.wrapper,
    {
      [styles.opened]: showBurgerMenu,
    },
    className,
  );

  const handleClose = () => {
    dispatch(setShowBurgerMenu(false));
  };

  const handleClick = (link: string) => {
    handleClose();
    const element = document.getElementById(link);

    if (element) {
      window.history.pushState({}, '', `#${link}`);
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handleLogoClick = () => {
    handleClose();
    push('/');
  };

  return (
    <>
      <Backdrop visible={showBurgerMenu && !!isTablet} onClick={handleClose} />
      <div className={wrapperClassName}>
        <div className={styles.content}>
          <div className={styles.header}>
            <Logo onClick={handleLogoClick} />
          </div>
          <div className={styles.links}>
            {navigation.map((item) => (
              <div
                key={item._id}
                className={styles.link}
                onClick={() => handleClick(item.href)}
              >
                {item.title}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
