'use client';
import cn from 'classnames';
import styles from './Footer.module.scss';
import { useTranslations } from 'next-intl';
import { Button } from '@/atoms/Button';
import Link from 'next/link';
import { CONTACTS } from '@/constants';
import { useEffect, useState } from 'react';
import { Logo } from '@/atoms/Logo';
import { useChangeLocale } from '@/hooks/useChangeLocale';

type FooterProps = {
  className?: string;
};

type FooterLinkProps = {
  text: string;
  url: string;
};

const FooterLink = ({ text, url }: FooterLinkProps) => {
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      window.open(url, '_blank');
    }
  };

  return (
    <Link
      href={url}
      target="_blank"
      className={styles.link}
      tabIndex={0}
      aria-label={text}
      onKeyDown={handleKeyDown}
      rel="noopener noreferrer"
    >
      {text}
    </Link>
  );
};

const FOOTER_LINKS = (t: (key: string) => string) => [
  { text: t('links.personalAccount'), url: '/' },
  { text: t('links.termsOfService'), url: '/' },
  { text: t('links.changelog'), url: '/' },
  { text: t('links.help'), url: '/' },
  { text: t('links.privacyPolicy'), url: '/' },
];

export function Footer({ className }: FooterProps) {
  const t = useTranslations('footer');
  const [copied, setCopied] = useState(false);
  const { changeLocale } = useChangeLocale();

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 3000);
    }
  }, [copied]);

  return (
    <footer className={cn(styles.wrapper, className)} role="contentinfo">
      <div className={styles.main}>
        <div className={styles.content}>
          <Logo variant="light" withText />
          <div className={styles.column}>
            <div className={styles.links}>
              {FOOTER_LINKS(t).map((link) => (
                <FooterLink key={link.text} text={link.text} url={link.url} />
              ))}
              <div className={styles.link} onClick={changeLocale}>
                {t('locale')}
              </div>
            </div>
            <div className={styles.contacts}>
              {CONTACTS.map((contact) => (
                <Link
                  className={styles.contact}
                  href={contact.link}
                  target="_blank"
                  key={contact._id}
                >
                  <img
                    src={contact.icon}
                    alt={contact.value}
                    className={styles.contactIcon}
                  />
                </Link>
              ))}
              <Button
                text={copied ? t('copied') : t('copy')}
                onClick={() => {
                  navigator.clipboard.writeText('mail@workice.ru');
                  setCopied(true);
                }}
                className={styles.button}
              />
            </div>
            <div className={styles.copyright}>{t('copyright')}</div>
          </div>
        </div>
      </div>
    </footer>
  );
}
