import styles from './Table.module.scss';
import cn from 'classnames';
import { useTranslations } from 'next-intl';
import { CardSubheading } from '../CardSubheading';
import { SkeletonStatic } from '../SkeletonStatic';

type TableProps = {
  className?: string;
};

const rows = Array.from({ length: 7 }, (_, i) => ({ _id: i }));

export function Table({ className }: TableProps) {
  const wrapperClassName = cn(styles.main, className);
  const t = useTranslations('home.features.table');

  return (
    <div className={wrapperClassName}>
      <CardSubheading
        text={t.rich('title', {
          nbsp: () => <>&nbsp;</>,
        })}
      />
      <div className={styles.wrapper}>
        <div className={styles.sidebar}>
          <img
            src="/png/logo-big-gray.png"
            alt="logo"
            className={styles.logo}
          />
        </div>
        <div className={styles.rows}>
          {rows.map((row, index) => {
            return (
              <SkeletonStatic
                key={row._id}
                className={cn(styles.row, {
                  [styles.lastRow]: index > rows.length - 5,
                })}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
