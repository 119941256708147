import { createReducer } from '@reduxjs/toolkit';

import {
  setActiveCustomer,
  setActiveOrder,
  setActiveProject,
  setShowBurgerMenu,
  setShowCustomerDrawer,
  setShowOrderDrawer,
  setShowProjectDrawer,
  setShowSidebarMenu,
} from './actions';
import { InitialState } from './types';

const initialState: InitialState = {
  showBurgerMenu: false,
  showSidebarMenu: false,
  showProjectDrawer: false,
  activeProject: '',
  showOrderDrawer: false,
  activeOrder: '',
  showCustomerDrawer: false,
  activeCustomer: '',
};

export const appReducer = createReducer(initialState, (builder) => {
  builder.addCase(setShowBurgerMenu, (state, action) => {
    state.showBurgerMenu = action.payload;
  });
  builder.addCase(setShowSidebarMenu, (state, action) => {
    state.showSidebarMenu = action.payload;
  });
  builder.addCase(setShowProjectDrawer, (state, action) => {
    state.showProjectDrawer = action.payload;
  });
  builder.addCase(setActiveProject, (state, action) => {
    state.activeProject = action.payload;
  });
  builder.addCase(setShowOrderDrawer, (state, action) => {
    state.showOrderDrawer = action.payload;
  });
  builder.addCase(setActiveOrder, (state, action) => {
    state.activeOrder = action.payload;
  });
  builder.addCase(setShowCustomerDrawer, (state, action) => {
    state.showCustomerDrawer = action.payload;
  });
  builder.addCase(setActiveCustomer, (state, action) => {
    state.activeCustomer = action.payload;
  });
});
