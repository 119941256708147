'use client';
import cn from 'classnames';
import styles from './Main.module.scss';
import { Button } from '@/atoms/Button';
import { useTranslations } from 'next-intl';
import { useRedirectToAccount } from '@/hooks/useRedirectToAccount';
import { Logo } from '@/atoms/Logo';
import { BLOCK_IDS } from '@/constants';
type MainProps = {
  className?: string;
};

export function Main({ className }: MainProps) {
  const wrapperClassName = cn(styles.wrapper, className);
  const t = useTranslations('home.main');
  const common = useTranslations('common');
  const { redirect } = useRedirectToAccount();

  const handleLearnMoreClick = () => {
    const element = document.getElementById(BLOCK_IDS.FEATURES);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <div className={wrapperClassName} id={BLOCK_IDS.HOME}>
      <div className={styles.heading}>
        <Logo
          className={styles.logo}
          withText
          textClass={styles.logoText}
          iconClass={styles.logoIcon}
        />
        <h1 className={styles.title}>
          {t.rich('title', {
            nbsp: () => <>&nbsp;</>,
          })}
        </h1>
        <p className={styles.desc}>
          {t.rich('description', {
            nbsp: () => <>&nbsp;</>,
          })}
        </p>
      </div>
      <div className={styles.actions}>
        <Button text={common('more')} onClick={handleLearnMoreClick} />
        <Button text={common('getStarted')} onClick={redirect} variant="dark" />
      </div>
    </div>
  );
}
