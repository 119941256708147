import { appReducer } from '.';
import { APP_REDUCER_NAME } from './constants';

type ReducerState = ReturnType<typeof appReducer>;

type State = {
  [APP_REDUCER_NAME]: ReducerState;
};

export const selectShowBurgerMenu = (state: State) =>
  state[APP_REDUCER_NAME].showBurgerMenu;

export const selectShowSidebarMenu = (state: State) =>
  state[APP_REDUCER_NAME].showSidebarMenu;

export const selectShowProjectDrawer = (state: State) =>
  state[APP_REDUCER_NAME].showProjectDrawer;

export const selectActiveProject = (state: State) =>
  state[APP_REDUCER_NAME].activeProject;

export const selectShowOrderDrawer = (state: State) =>
  state[APP_REDUCER_NAME].showOrderDrawer;

export const selectActiveOrder = (state: State) =>
  state[APP_REDUCER_NAME].activeOrder;

export const selectShowCustomerDrawer = (state: State) =>
  state[APP_REDUCER_NAME].showCustomerDrawer;

export const selectActiveCustomer = (state: State) =>
  state[APP_REDUCER_NAME].activeCustomer;
