'use client';
import cn from 'classnames';
import styles from './About.module.scss';
import { useTranslations } from 'next-intl';
import { Button } from '@/atoms/Button';
import { useRedirectToAccount } from '@/hooks/useRedirectToAccount';
import { BLOCK_IDS } from '@/constants';

type AboutProps = {
  className?: string;
};

export function About({ className }: AboutProps) {
  const wrapperClassName = cn(styles.wrapper, className);
  const t = useTranslations('home.about');
  const { redirect } = useRedirectToAccount();

  return (
    <div className={wrapperClassName} id={BLOCK_IDS.ABOUT}>
      <div className={styles.content}>
        <h2 className={styles.title}>{t('title')}</h2>
        <div className={styles.welcome}>
          <div className={styles.welcomeLeft}>
            <div className={styles.welcomeTextWrapper}>
              <h3 className={styles.welcomeTitle}>
                {t('welcome.title')}
                <span className={styles.welcomeTitleSpan}>Workice</span>
              </h3>
              <p className={styles.welcomeText}>{t('welcome.text')}</p>
            </div>
            <Button
              text={t('welcome.button')}
              variant="dark"
              onClick={redirect}
              className={styles.buttonDesktop}
            />
          </div>
          <div className={styles.welcomeRight}>
            <div className={styles.welcomeTop}>
              <div className={styles.welcomeImage}>
                <img
                  src="/png/logo-big-gray.png"
                  alt="logo"
                  className={styles.logo}
                />
              </div>
              <div className={styles.welcomeItems}>
                <div className={styles.welcomeItem} />
                <div className={styles.welcomeItem} />
              </div>
            </div>
            <div className={styles.welcomeBottom}>
              <div className={styles.welcomeItem} />
              <div className={styles.welcomeItem} />
              <div className={styles.welcomeItem} />
            </div>
          </div>
          <Button
            text={t('welcome.button')}
            variant="dark"
            onClick={redirect}
            className={styles.buttonMobile}
          />
        </div>
      </div>
    </div>
  );
}
